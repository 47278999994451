<template>
  <div class="rating-region">
    <div class="rating-region__left">
      <h1 class="rating-region__left-title">{{ $t('rating-of-regions') }}</h1>
      <router-link
          to="/region-indicators/filling-indicators"
          class="rating-region__left-link"
          v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'"
      >{{ $t('fill-in-indicators') }}</router-link>
    </div>
    <RatingRegionContent />
  </div>
</template>

<script>
import RatingRegionContent from "@/components/template/rating-region/RatingRegionContent.vue";
import {mapGetters} from "vuex";

export default {
  name: "RatingRegion",
  components: {RatingRegionContent},
  computed: {
    ...mapGetters(['getUser'])
  }
}
</script>

<style lang="scss" scoped>
.rating-region {
  padding: 150px 50px 50px 50px;
  background: #F5F5F5;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 1200px) {
    padding: 104px 20px 50px 20px;
    flex-direction: column;
    gap: 30px;
  }

  &__left {

    display: flex;
    flex-direction: column;
    gap: 48px;

    &-title {
      color: #1B1A1F;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;
    }

    &-link {
      color: #1B1A1F;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }
  }
}
</style>
